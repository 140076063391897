import React, {useEffect, useState} from 'react';
import Forms from './Forms';
// import API from "../utils/API";
// import {API_ENDPOINTS} from "../apiConfig";
import {useTranslation} from "react-i18next";
import crossIcon from "../assets/images/cross.svg";

const RepairEditorRepair = ({deviceList, repairData, onRepairChange, onPartsChange, onRemoveRepair}) => {
    // const [selectedDevice, setSelectedDevice] = useState(repairData?.device || null);
    const [repairOptions, setRepairOptions] = useState([]);
    // const [partsUsed, setPartsUsed] = useState(repairData?.partsUsed || []);
    // const [partsOptions, setPartsOptions] = useState([]);
    const {t} = useTranslation();

    // const handlePartChange = (index, field, value) => {
    //     const updatedPartsUsed = [...partsUsed];
    //     updatedPartsUsed[index] = {...updatedPartsUsed[index], [field]: value};
    //     setPartsUsed(updatedPartsUsed);
    //     onPartsChange(updatedPartsUsed);
    // };

    // const fetchParts = async () => {
    //     try {
    //         const {data} = await API.get(`${API_ENDPOINTS.part.listByDevice}/${selectedDevice.value}`);
    //         setPartsOptions(data.map(part => ({label: `${part.name} (${part.quantity} ks)`, value: part._id})));
    //     } catch (error) {
    //         console.error("Failed to fetch devices:", error);
    //     }
    // }
    //
    // useEffect(() => {
    //     if (selectedDevice) {
    //         fetchParts();
    //     }
    // }, [selectedDevice]);

    // const handleAddPart = () => {
    //     setPartsUsed([...partsUsed, {part: '', quantity: 1}]);
    // };

    const handleRepairChange = (value) => {
        onRepairChange({...repairData, repairList: value});
    };

    const handleSelectedDevice = (device) => {
        const chosenDevice = deviceList.find(d => d._id === device.value);
        setRepairOptions(chosenDevice?.repairList.map(r => ({
            label: `${r.repair.name} (${r.price} Kč)`,
            value: r._id,
            price: r.price
        })) || []);
        onRepairChange({device, repairList: []});
    };

    const _renderRepairOptions = () => {
        if (repairData.device && !repairOptions.length) {
            return <label>{t('RepairEditorRepair.noAvailableRepairs')}</label>
        }

        return repairOptions.length > 0 && (
            <Forms.Select
                multiple
                required
                placeholder={t('selectRepair')}
                value={repairData?.repairList || []}
                options={repairOptions}
                onChange={handleRepairChange}
            />
        )
    }

    return (
        <div className="grid grid-cols-[1fr_auto] gap-2 w-full mb-8">
            <div>
                <Forms.Select
                    required
                    placeholder={t('RepairEditorRepair.selectDevice')}
                    value={repairData.device}
                    options={deviceList.map(device => ({value: device._id, label: device.name}))}
                    onChange={handleSelectedDevice}
                />

                {_renderRepairOptions()}

                {/*{partsUsed.map((partUsed, index) => (*/}
                {/*    <div key={index} className="grid gap-4 mb-4 items-start">*/}
                {/*        <Forms.Select*/}
                {/*            required*/}
                {/*            placeholder={t('selectPart')}*/}
                {/*            value={partUsed.part}*/}
                {/*            options={partsOptions}*/}
                {/*            onChange={(val) => handlePartChange(index, 'part', val)}*/}
                {/*        />*/}
                {/*        <Forms.Text*/}
                {/*            required*/}
                {/*            type="number"*/}
                {/*            placeholder="Quantity"*/}
                {/*            value={partUsed.quantity}*/}
                {/*            onChange={(val) => handlePartChange(index, 'quantity', val)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*))}*/}
                {/*{selectedDevice &&*/}
                {/*    <Forms.Button type="button" onClick={handleAddPart} label={t('addPart')} className="mt-4"/>}*/}
            </div>
            <div className="flex justify-center">
                <img src={crossIcon} width={25} alt="delete icon" className="cursor-pointer"
                     onClick={onRemoveRepair}/>
            </div>
            <div className="separator col-span-2 mt-4"/>
        </div>
    );
};

export default RepairEditorRepair;
