import React, {useState} from 'react';
import RepairEditorRepair from './RepairEditorRepair';
import Forms from "./Forms";
import {useTranslation} from "react-i18next";

const RepairEditor = ({deviceList, onRepairDataChange}) => {
    const {t} = useTranslation();
    const [repairs, setRepairs] = useState([{device: '', repairList: [], partsUsed: []}]);

    const handleAddRepair = () => {
        setRepairs([...repairs, {device: null, repairList: [], partsUsed: []}]);
    };

    const handleRepairChange = (index, repairData) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index] = {...updatedRepairs[index], ...repairData};
        setRepairs(updatedRepairs);
        onRepairDataChange(updatedRepairs);
    };

    const handlePartsChange = (index, partsUsed) => {
        const updatedRepairs = [...repairs];
        updatedRepairs[index].partsUsed = partsUsed;
        setRepairs(updatedRepairs);
        onRepairDataChange(updatedRepairs);
    };

    const onRemoveRepair = (index) => {
        setRepairs(oldState => {
            return oldState.filter((_, i) => index !== i)
        });
    }

    return (
        <div>
            {repairs.map((repair, index) => (
                <RepairEditorRepair
                    selectedDevices={repairs.map(rep => rep.device).filter(i => i && i)}
                    key={index}
                    deviceList={deviceList}
                    repairData={repair}
                    onRepairChange={(repairData) => handleRepairChange(index, repairData)}
                    onPartsChange={(partsUsed) => handlePartsChange(index, partsUsed)}
                    onRemoveRepair={() => onRemoveRepair(index)}
                />
            ))}
            <Forms.Button type="button" onClick={handleAddRepair} label={t('RepairEditor.addRepair')} className="mt-4"/>
        </div>
    );
};

export default RepairEditor;
