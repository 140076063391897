import React, {useState, useEffect, useRef} from 'react';
import {API_ENDPOINTS} from "../../../apiConfig";
import DataGrid from "../../../components/DataGrid";
import ConfirmModal from "../../../components/ConfirmModal";
import API from "../../../utils/API";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Forms from "../../../components/Forms";
import {ReactComponent as DeleteIcon} from '../../../assets/images/delete.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';
import RepairModal from "../../../components/RepairModal";
import {formatPrice} from "../../../utils/tools";
import {useNavigate} from 'react-router-dom';
import routes from "../../../routesConfig";

const Parts = (...props) => {
    const [repairs, setRepairs] = useState([]);
    const [filteredRepairs, setFilteredRepairs] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [repairToDelete, setRepairToDelete] = useState();
    const {t} = useTranslation();
    const [error, setError] = useState();
    const navigate = useNavigate();

    const filterInputRef = useRef(null);

    const fetchRepairs = async () => {
        const response = await API.get(API_ENDPOINTS.repairs.list);
        const repairs = response.data.map(item => ({...item, key: item.id}));
        setRepairs(repairs);
        setFilteredRepairs(repairs);
    };

    useEffect(() => {
        fetchRepairs();
    }, []);

    useEffect(() => {
        const filtered = repairs.filter((repair) => {
            const filterLowerCase = filterText.toLowerCase();
            return repair.customerInfo.name.toLowerCase().includes(filterLowerCase);
        });
        setFilteredRepairs(filtered);
    }, [filterText, repairs]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!e.shiftKey && filterInputRef.current && document.activeElement !== filterInputRef.current && !isModalOpen && !isConfirmModalOpen) {
                filterInputRef.current.focus();
            }

            if (e.shiftKey && e.key === 'N') {
                e.preventDefault();
                openNewDeviceModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, isConfirmModalOpen]);

    const handleFilterKeyDown = (e) => {
        if (e.key === 'Escape') {
            setFilterText('');
        }
    };

    const handleDeletePart = (part) => {
        setRepairToDelete(part);
        setIsConfirmModalOpen(true);
    };

    const columns = [
        {
            key: 'name',
            name: t('customerName'),
            renderCell: ({row}) => <label>{row.customerInfo.name} {row.customerInfo.surname}</label>
        },
        {
            key: 'dueDate',
            name: t('repairs.dueDateLabel'),
            renderCell: ({row}) => moment(row.dueDate).format('DD.MM.YYYY')
        },
        {
            key: 'repairsCount',
            name: t('repairs.repairsCunt'),
            renderCell: ({row}) => row.repairs.reduce((acc, repair) => {
                return acc + repair.repairList.length;
            }, 0)
        },
        {
            key: 'status',
            name: t('status'),
            renderCell: ({row}) => t(`${row.status}Status`)
        },
        {
            key: 'estimatedPrice',
            name: t('estimatedPrice'),
            renderCell: ({row}) => formatPrice(row.estimatedPrice)
        },
        {
            key: 'createdBy',
            name: t('createdBy'),
            renderCell: ({row}) => `${row.createdBy.name} ${row.createdBy.surname}`
        },
        {
            key: 'createdAt',
            name: t('createdAt'),
            renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY HH:mm')
        },
        {
            key: 'actions',
            width: 60,
            name: '',
            renderCell: ({row}) => {
                return (
                    <div className="flex justify-center items-center">
                        <EditIcon className="w-4 cursor-pointer text-blue-800 h-full"
                                  onClick={() => navigate(routes.admin.getEditRepair(row._id))}/>
                        <DeleteIcon className="w-4 cursor-pointer fill-red-700 m-2"
                                    onClick={() => handleDeletePart(row)}/>
                    </div>
                );
            }
        },
    ];

    const confirmDeletePart = async () => {
        try {
            await API.delete(`${API_ENDPOINTS.repairs.delete}/${repairToDelete._id}`);
            setRepairs(repairs.filter(category => category._id !== repairToDelete._id));
            setIsConfirmModalOpen(false);
        } catch (error) {
            setError(error);
            console.error("Failed to delete category:", error);
        }
    };

    const openNewDeviceModal = () => {
        setIsModalOpen(true);
    };

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setError(null);
    };

    const _rowClassRenderer = (row) => {
        if (new Date() > new Date(row.dueDate)) {
            return 'row-deadline';
        }

        switch (row.status) {
            case 'pending':
                return 'row-pending';
            case 'in_process':
                return 'row-in-process';
            case 'completed':
                return 'row-completed';
            case 'cancelled':
                return 'row-cancelled';
            default:
                return null;
        }
    }

    return (
        <div>
            <Forms.Button label={t('repairs.addRepair')} onClick={openNewDeviceModal} className="mb-8"/>

            <div className="grid grid-cols-4 items-end gap-4 mb-4">
                <Forms.Text
                    ref={filterInputRef}
                    value={filterText}
                    onChange={val => setFilterText(val)}
                    onKeyDown={handleFilterKeyDown}
                    label={t('name')}
                    placeholder={t('searchByName')}
                />
            </div>
            <DataGrid
                showCreatedBy
                rows={filteredRepairs}
                columns={columns}
                rowClassRenderer={_rowClassRenderer}
            />
            <RepairModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onPartUpdate={fetchRepairs}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={onCloseConfirmModal}
                error={error}
                onConfirm={confirmDeletePart}
                title={t('confirmDeletion')}
                message={t('confirmRepairDeletion', {title: repairToDelete?.name})}
            />
        </div>
    );
};

export default Parts;
