import React from 'react';
import Select from 'react-select';

const SelectInput = ({label, name, value, onChange, options, placeholder, multiple = false, className = ''}) => {

    const customFilterOption = (option, searchText) => {
        const label = option.label.toLowerCase();
        const code = option.data.code?.toLowerCase() || '';
        const search = searchText.toLowerCase();

        return label.includes(search) || code.includes(search);
    };

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <Select
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                isMulti={multiple}
                placeholder={placeholder}
                className={`react-select-container ${className}`}
                classNamePrefix="react-select"
                filterOption={customFilterOption}
            />
        </div>
    );
};

export default SelectInput;
