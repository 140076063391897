import * as React from "react";
import moment from "moment";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'min', 'max'];

function DateInput(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === 'value') {
                value = moment(value).format('YYYY-MM-DD');
            } else if (propName === 'min' || propName === 'max') {
                value = moment(value).format('YYYY-MM-DD');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    return (<label className="col-50">
        <label>{props.label}</label>
        <input type="date" {...getMainProps(inputMainProps)} disabled={disabled}
               className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm
                 rounded-t-md`}/>
    </label>);
}

export default DateInput;