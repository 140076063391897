const routes = {
    home: '/',
    login: '/login',
    forgotPassword: '/forgot-password',
    admin: {
        base: '/admin',
        dashboard: '/dashboard',
        repairs: '/repairs',
        getEditRepair: id => `/admin/repairs/${id}`,
        addRepair: '/repairs/add',
        devices: '/devices',
        dashboardFull: '/admin/dashboard',
        purchaseFull: '/admin/purchase',
        repairsFull: '/admin/repairs',
        inventoryFull: '/admin/inventory',
        devicesFull: '/admin/devices',
        part: {

            base: '/admin/part',

            categories: '/part/categories',
            parts: '/part/items',

            categoriesFull: '/admin/part/categories',
            itemsFull: '/admin/part/items'
        },
        device: {

            base: '/admin/device',

            categories: '/device/categories',
            items: '/device/items',
            repairCatalog: '/device/repairCatalog',

            categoriesFull: '/admin/device/categories',
            itemsFull: '/admin/device/items',
            repairCatalogFull: '/admin/device/repairCatalog',
        }
    }
};

export default routes;
