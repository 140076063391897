export const API_ENDPOINTS = {
    login: '/login',
    refreshToken: '/refreshToken',
    dashboardData: '/dashboardData',
    part: {
        categories: '/partCategory',
        list: '/part',
        add: '/part',
        edit: '/part',
        listByDevice: '/part/listByDevice'
    },
    repairs: {
        add: '/repair',
        list: '/repair/list',
        delete: '/repair',
    },
    device: {
        listCategories: '/device/listCategories',
        list: '/device',
        add: '/device',
        base: '/device',
        category: 'device/category',
        catalogRepairs: '/device/catalogRepair',
        listRepairsByDevice: '/device/listRepairsByDevice'
    },
    user: {
        resetPassword: '/resetPassword',
        setNewPassword: '/setNewPassword'
    }
};