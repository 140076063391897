import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';
import RepairEditor from "./RepairEditor";

const RepairModal = ({isOpen, onClose, onPartUpdate, repair}) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('Česká republika');
    const [repairs, setRepairs] = useState([]);
    const [note, setNote] = useState('');
    const [error, setError] = useState('');
    const [deviceList, setDeviceList] = useState([]);
    const [dueDate, setDueDate] = useState('');
    const [estimatedPrice, setEstimatedPrice] = useState(0);
    const {t} = useTranslation();
    const inputRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    const resetForm = () => {
        setName('');
        setSurname('');
        setEmail('');
        setPhone('');
        setRepairs({});
    };

    const fetchDevices = async () => {
        try {
            const response = await API.get(API_ENDPOINTS.device.list);
            setDeviceList(response.data);
        } catch (error) {
            console.error("Failed to fetch devices:", error);
        }
    };

    useEffect(() => {
        fetchDevices();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError();
        try {
            const repairData = {
                customerInfo: {
                    name,
                    surname,
                    email,
                    phone,
                    address:
                        {
                            street,
                            houseNumber,
                            zip,
                            country
                        }
                },
                repairs: repairs.map(repair => ({
                        device: repair.device.value,
                        repairList: repair.repairList.map(({value, price}) => ({repair: value, price}))
                    })
                ),
                estimatedPrice: _getEstimatedPrice()
            };
            if (repair) {
                await API.put(`${API_ENDPOINTS.repairs.edit}/${repair._id}`, repairData);
            } else {
                await API.post(API_ENDPOINTS.repairs.add, repairData);
            }
            handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const handleClose = () => {
        onClose();
    }

    const _getEstimatedPrice = () => {
        return repairs.reduce((total, repair) => {
            const totalPerDevice = repair.repairList.reduce((deviceTotalPrice, device) => {
                if (device.price) {
                    deviceTotalPrice += device.price;
                }
                return deviceTotalPrice;
            }, 0);
            return total + totalPerDevice;
        }, 0);

    }

    const _renderPrice = () => {
        let totalPrice = _getEstimatedPrice();

        if (!totalPrice) return null;

        totalPrice = new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(
            totalPrice,
        )
        return <label className="text-green-600">{t('repairs.orderPrice', {price: totalPrice})}</label>;
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 shadow-lg rounded-lg w-2/4">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {repair ? t('repairs.editRepair') : t('repairs.addNewRepair')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <h3>{t('repairs.customerBasicInfo')}</h3>
                    <div className="grid grid-cols-2 gap-4 mb-4 items-start">
                        <Forms.Text
                            ref={inputRef}
                            required
                            placeholder={t('name')}
                            value={name}
                            onChange={(val) => setName(val)}
                            className="rounded-t-md"
                        />
                        <Forms.Text
                            required
                            placeholder={t('surname')}
                            value={surname}
                            onChange={(val) => setSurname(val)}
                            className="rounded-t-md"
                        />
                        <Forms.Text
                            required
                            placeholder={t('phone')}
                            value={phone}
                            onChange={(val) => setPhone(val)}
                            className="rounded-t-md"
                        />
                        <Forms.Text
                            required
                            placeholder={t('email')}
                            value={email}
                            onChange={(val) => setEmail(val)}
                            className="rounded-t-md"
                        />
                    </div>
                    <div>
                        <h3>{t('repairs.customerAddress')}</h3>
                        <div className="grid grid-cols-2 gap-4 mb-4 items-start">
                            <Forms.Text
                                required
                                placeholder={t('street')}
                                value={street}
                                onChange={(val) => setStreet(val)}
                                className="rounded-t-md"
                            />
                            <Forms.Text
                                required
                                placeholder={t('houseNumber')}
                                value={houseNumber}
                                onChange={(val) => setHouseNumber(val)}
                                className="rounded-t-md"
                            />
                            <Forms.Text
                                required
                                placeholder={t('zip')}
                                value={zip}
                                onChange={(val) => setZip(val)}
                                className="rounded-t-md"
                            />
                            <Forms.Text
                                required
                                placeholder={t('country')}
                                value={country}
                                onChange={(val) => setCountry(val)}
                                className="rounded-t-md"
                            />
                        </div>
                    </div>

                    <div>
                        <Typography variant="h6"
                                    className="flex justify-between"><label>{t('repairs.repairsEnum')}</label> {_renderPrice()}
                        </Typography>
                        {deviceList && <RepairEditor deviceList={deviceList} onRepairDataChange={setRepairs}/>}
                    </div>

                    <div className="mt-8">
                        <h3>{t('repairs.orderInfo')}</h3>
                        <div className="flex flex-col">
                            <Forms.Textarea
                                required
                                placeholder={t('note')}
                                value={note}
                                onChange={(val) => setNote(val)}
                                className="rounded-t-md"
                            />
                            <Forms.Date
                                label={t('repairs.dueDate')}
                                value={dueDate}
                                onChange={(val) => setDueDate(val)}
                            />
                        </div>
                    </div>

                    {error && <Error error={error}/>}
                    <Forms.Button label={repair ? t('update') : t('save')} className="mt-4"/>
                </form>
            </Box>
        </Modal>
    );
};

export default RepairModal;
