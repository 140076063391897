import React, {useEffect, useRef, useState} from 'react';
import {Modal, Box, Typography} from '@mui/material';
import {API_ENDPOINTS} from "../apiConfig";
import API from "../utils/API";
import Error from "./Error";
import Forms from "./Forms";
import {useTranslation} from 'react-i18next';

const DeviceCategoryModal = ({isOpen, onClose, onCategoryAdded, category}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const inputRef = useRef(null);

    const [error, setError] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        setName(category ? category.name : '');
        setDescription(category ? category.description : '');
    }, [category]);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 1);
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e?.preventDefault();
        setError();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        file && formData.append('file', file);
        console.log(file);
        try {
            if (category) {
                await API.put(`${API_ENDPOINTS.device.category}/${category._id}`, formData);
            } else {
                await API.post(API_ENDPOINTS.device.category, {name, description});
            }
            // onCategoryAdded();
            // handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const handleClose = () => {
        onClose();
    }

    return (<Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4 shadow-lg rounded-lg">
            <form onSubmit={handleSubmit}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {category ? 'Upravit kategorii' : 'Přidat novou kategorii'}
                </Typography>
                <Forms.Text
                    ref={inputRef}
                    required
                    placeholder="Název"
                    value={name}
                    onChange={(val) => setName(val)}
                    className="rounded-t-md"
                />
                <Forms.Text
                    required
                    placeholder="Popis"
                    value={description}
                    onChange={(val) => setDescription(val)}
                    className="rounded-b-md"
                />
                <Forms.File
                    required
                    placeholder="Ikona kategorie"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="rounded-b-md"
                />
                {error && <Error error={error}/>}
                <Forms.Button
                    onClick={handleSubmit}
                    label={category ? t('update') : t('save')}
                    className="mt-4"/>
            </form>
        </Box>
    </Modal>);
};

export default DeviceCategoryModal;
