import TextInput from './TextInput';
import TextareaInput from './TextareaInput';
import NumberInput from './NumberInput';
import PasswordInput from './PasswordInput';
import EmailInput from './EmailInput';
import CheckboxInput from "./CheckboxInput";
import Button from "./Button";
import SelectInput from "./SelectInput";
import File from "./File";
import MultiCheckboxInput from "./MultiCheckboxInput";
import DateInput from "./DateInput";

const Forms = {
    Text: TextInput,
    Textarea: TextareaInput,
    Number: NumberInput,
    Password: PasswordInput,
    Email: EmailInput,
    Checkbox: CheckboxInput,
    MultiCheckbox: MultiCheckboxInput,
    Button: Button,
    Select: SelectInput,
    File: File,
    Date: DateInput
};

export default Forms;
