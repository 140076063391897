import React from 'react';
import {default as ReactDataGrid} from "react-data-grid";
import './DataGrid.css';

const DataGrid = ({rows, columns, rowClassRenderer = null, ...props}) => {
    return (
        <div className="data-grid-container">
            <ReactDataGrid
                className="rdg-light border"
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[5]}
                {...props}
                rowClass={rowClassRenderer}
            />
        </div>
    );
};

export default DataGrid;